<template>
    <AppContainer>
        <EditBusinessProfile/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import EditBusinessProfile from '@/components/dashboard/business/EditBusinessProfile.vue'
export default {
  components: {
    AppContainer,
    EditBusinessProfile
  }
}
</script>