<template>
<div class="email-campaign px-2 px-md-4">
    <div class="step1-area">
        <div class="email-deatils">
            <h3>Edit Business Profile</h3>
            <div class="row mb-3">
                <div class="col-12">
                    <div class="banner-img">
                        <img :src="imgDataUrl" class="img-fluid">
                        <a href="javascript:void(0)" class="camera_icon bg-white" title="Upload Profile Image" @click="toggleShow">
                            <fa icon="camera" />
                        </a>
                        <myUpload field="img" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess" @crop-upload-fail="cropUploadFail" v-model="show"  :params="params" :headers="headers" img-format="jpg" langType="en" :noCircle="true"></myUpload>
                    </div>
                    <input type="file" name="upload" id="upload_image" class="form-control upload-box p-1 d-none" />
                </div>
            </div>
            <div class="form-row ">
                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                    <label>Business Title</label>
                    <input v-model="business_.title" :class="[v$.business_.title.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="Ondaq Beauty Salon" />
                </div>
                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                    <label>Business Phone</label>
                    <vue-tel-input v-if="business_.dialCode" v-model="business_.phone" @country-changed="updateCountryCode" :class="[v$.business_.phone.$error ? 'border-danger': '']" :onlyCountries="['US','PK']" :defaultCountry="business_.dialCode ? business_.dialCode:1" v-mask="'(###) ### - ####'" :inputOptions="{type:'tel',placeholder:'(000) 000 - 0000'}" :dropdownOptions="{showDialCodeInList:true,showFlags:true,showDialCodeInSelection:true}" :autoFormat="false" mode="international"></vue-tel-input>
                </div>
                <div class="form-group col-12">
                    <label style="font-size:17px" >Business Description</label>
                    <a href="#" class="form-text ai-description-link" v-on:click.prevent="generateAIDescription">
                        Generate AI Description
                    </a>
                    <textarea v-model="business_.description" :class="[v$.business_.description.$error ? 'border-danger': '']" rows="5" style="resize: none" class="form-control" placeholder="Please enter brief detail of your business here."></textarea>
                </div>
                <div class="form-group col-sm-6">
                <label>Time Zone</label>
                    <select id="timezone" class="form-control" v-model="business_.timeZone">
                         <option v-for="(label, value) in this.timeZones" :value="value" :key="value">{{ label }}</option>
                    </select>
                </div>
            </div>
            <h3>Business Address</h3>
            <div class="row mt-3">
                <div class="col-md-6">
                    <div class="form-group">
                        <label style="font-size:17px;" class="mb-2">Full Address</label>
                        <input v-model="business_.address.complete_address" :class="[v$.business_.address.complete_address.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="Search Address..." ref="origin" />
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>City</label>
                                <input v-model="business_.address.city" :class="[v$.business_.address.city.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="Charlotte" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>State</label>
                                <input v-model="business_.address.state" :class="[v$.business_.address.state.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="North Carolina" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Street</label>
                        <input v-model="business_.address.street" :class="[v$.business_.address.street.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="100 John Doe Street" />
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Zipcode</label>
                                <input v-model="business_.address.zip" :class="[v$.business_.address.zip.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="12345" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Apt/Suite No</label>
                                <input v-model="business_.apt_suite" type="text" class="form-control" placeholder="Apt/Suite No" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div ref="map" style="height:275px;width:100%;margin-top: 15px;"></div>
                </div>
            </div>
            <!-- Facilities -->
            <h3>Facilities</h3>
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="containerw">Free Parking
                          <input type="checkbox" v-model="business_.facilities.free_parking" true-value="Yes" false-value="No">
                          <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="containerw">Free Wifi
                          <input type="checkbox" v-model="business_.facilities.wifi" true-value="Yes" false-value="No">
                          <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="containerw">Reception
                          <input type="checkbox" v-model="business_.facilities.reception" true-value="Yes" false-value="No">
                          <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="containerw">Kids Friendly
                          <input type="checkbox" v-model="business_.facilities.kids_friendly" true-value="Yes" false-value="No">
                          <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group mt-3 p-0">
                        <label>Language:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="containerw">English
                          <input type="checkbox" v-model="business_.facilities.language" value="English">
                          <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="containerw">Spanish
                          <input type="checkbox" v-model="business_.facilities.language" value="Spanish">
                          <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <!-- <div class="col-md-3">
                    <div class="form-group">
                        <label class="containerw">French
                          <input type="checkbox" v-model="business_.facilities.language" value="French">
                          <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="containerw">Hindi
                          <input type="checkbox" v-model="business_.facilities.language" value="Hindi">
                          <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="containerw">Deutsch
                          <input type="checkbox" v-model="business_.facilities.language" value="Deutsch">
                          <span class="checkmark"></span>
                        </label>
                    </div>
                </div> -->
                <div class="col-12">
                    <div class="form-group mt-3">
                        <label>Payment Methods:</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="containerw">Credit Card
                          <input type="checkbox" v-model="business_.facilities.Payment_method" value="Credit">
                          <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label class="containerw">Cash
                          <input type="checkbox" v-model="business_.facilities.Payment_method" value="Cash">
                          <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
            </div>
            <h3>Special Instructions</h3>

            <div class="form-group mt-2">
                <textarea v-model="business_.special_instructions" rows="3" style="resize: none" class="form-control" placeholder="Please enter special instructions here."></textarea>
            </div>
            <h3>Status</h3>

            <div class="form-row mb-4 mt-3">
                <div class="form-group col-sm-6">
                    <select v-model="business_.status" class="form-control">
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                    </select>
                </div>
                
                <div class="col-12"><p class="small">Note: When you update your profile as active, it will automatically be posted to the search. We recommend you to complete your profile with the business description and business logo or an image.</p></div>
            </div>
            <!-- <div class="form-row mt-4">
                <div class="form-group col-12">
                    <label>Business Description</label>
                    <textarea v-model="business_.description" :class="[v$.business_.description.$error ? 'border-danger': '']" rows="5" style="resize: none" class="form-control" placeholder="Please enter brief detail of your business here."></textarea>
                </div>
            </div> -->
            <!-- <div class="row"> -->
                <!-- <div class="col-md-6">
                    <div class="banner-img">
                        <img :src="imgDataUrl">
                        <a href="javascript:void(0)" class="camera_icon bg-white" title="Upload Profile Image" @click="toggleShow">
                            <fa icon="camera" />
                        </a>
                    <myUpload field="img" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess" @crop-upload-fail="cropUploadFail" v-model="show" :width="500" :height="500" :params="params" :headers="headers" img-format="jpg" langType="en" :noCircle="true"></myUpload>
                    </div>
                    <input type="file" name="upload" id="upload_image" class="form-control upload-box p-1 d-none" />
                </div> -->
                <!-- <div class="col-md-6">
                    <div class="form-group">
                        <label>Special Instructions</label>
                        <textarea v-model="business_.special_instructions" rows="3" style="resize: none" class="form-control" placeholder="Please enter special instructions here."></textarea>
                    </div>
                    <div class="form-group">
                        <label>Cancellation Policy</label>
                        <textarea v-model="business_.cancellation_policy" rows="3" style="resize: none" class="form-control" placeholder="Please enter cancellation policy here."></textarea>
                            :class="[v$.business_.cancellation_policy.$error ? 'border-danger': '']"
                    </div>
                </div> -->
            <!-- </div> -->
            <!-- <div class="form-row">
                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                    <label>Special Instructions</label>
                    <textarea v-model="business_.special_instructions" :class="[v$.business_.special_instructions.$error ? 'border-danger': '']" rows="3" style="resize: none" class="form-control" placeholder="Please enter special instructions here."></textarea>
                </div>
                <div class="form-group col-sm-6 col-md-6 col-lg-6">
                    <label>Cancellation Policy</label>
                    <textarea v-model="business_.cancellation_policy" :class="[v$.business_.cancellation_policy.$error ? 'border-danger': '']" rows="3" style="resize: none" class="form-control" placeholder="Please enter cancellation policy here."></textarea>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="banner-img">
                        <img :src="imgDataUrl">
                        <a href="javascript:void(0)" class="camera_icon bg-white" title="Upload Profile Image" @click="toggleShow">
                            <fa icon="camera" />
                        </a>
                        <myUpload field="img" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess" @crop-upload-fail="cropUploadFail" v-model="show" :width="1200" :height="600" :params="params" :headers="headers" img-format="jpg" langType="en" noCircle="true"></myUpload>
                    </div>
                    <input type="file" name="upload" id="upload_image" class="form-control upload-box p-1 d-none" />
                </div>
                <div class="col-md-6">
                    <div class="banner-img">
                        <img :src="imgDataUrl">
                        <a href="javascript:void(0)" class="camera_icon bg-white" title="Upload Profile Image" @click="toggleShow">
                            <fa icon="camera" />
                        </a>
                        <myUpload field="img" @crop-success="cropSuccess" @crop-upload-success="cropUploadSuccess" @crop-upload-fail="cropUploadFail" v-model="show" :width="500" :height="500" :params="params" :headers="headers" img-format="jpg" langType="en" noCircle="true"></myUpload>
                    </div>
                    <input type="file" name="upload" id="upload_image" class="form-control upload-box p-1 d-none" />
                </div>
            </div> -->
        </div>

        <div class="modal-footer campaign-footer d-flex">
            <router-link :to="{name:'dealsList'}" class="add-btn"> Cancel</router-link>
            <a href="#" class="rem-btn" v-on:click.prevent="updateProfile"> Update</a>
        </div>
    </div>

    <div class="modal fade" id="AIDescriptionModal" tabindex="-1" role="dialog" aria-labelledby="AIDescriptionModalLabel" aria-hidden="true" >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="AIDescriptionModalLabel"> Description </h5>
                    <a class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><img class="img-fluid" src="../../../assets/images/icons/Closex.svg"  alt="" /></span>
                    </a>
                </div>
                <div class="modal-body">
                    <div class="add-member">
                        <div class="form-row">
                            <div class="form-group col-12" >
                                <textarea class="form-control" v-model="AIDescription" placeholder="Business Description....."></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="add-btn" data-bs-dismiss="modal">Cancel</button>
                    <button v-on:click="updateDescription(AIDescription)" class="rem-btn">Update</button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    import axios from 'axios'
    import {mask} from 'vue-the-mask'
    import { VueTelInput } from 'vue-tel-input'
    import 'vue-tel-input/dist/vue-tel-input.css'
    import useVuelidate from '@vuelidate/core'
    // import { required,email,requiredIf,numeric,sameAs } from '@vuelidate/validators'
    import { required } from '@vuelidate/validators'
    import { useToast } from "vue-toastification"
    import myUpload from 'vue-image-crop-upload'
    import $ from 'jquery'

    export default {
        name:'EditBusinessProfile',
        components: {
            VueTelInput,
            myUpload
        },
        setup () {
            const toast = useToast();
            return { v$: useVuelidate(),toast }
        },
        data() {
            return {
                business_:{
                    title:'',
                    phone:'',
                    dialCode: null,
                    address:{
                        complete_address:'',
                        lat:'',
                        lng:'',
                        country:'',
                        state:'',
                        city:'',
                        street:'',
                        zip:'',
                        neighborhood:''
                    },
                    facilities:{
                        free_parking:'No',
                        wifi:'No',
                        reception:'No',
                        kids_friendly:'No',
                        language:[],
                        Payment_method:[]
                    },
                    apt_suite:'',
                    profile_pic:'',
                    description:'',
                    special_instructions:'',
                    cancellation_policy:'',
                    status:'',
                    business_id: this.$storage.getStorageSync('business').active_business.id,
                    timeZone:""
                },
                business_detail:{},
                show: false,
                params: {
                    token: '123456798',
                    name: 'avatar'
                },
                headers: {
                    smail: '*_~'
                },
                imgDataUrl:'',
                timeZones:[],
                AIDescription:''
            };
        },
        validations () {
            return {
                business_:{
                    title:{ required },
                    phone:{ required },
                    address:{
                        complete_address:{ required },
                        lat:{ required },
                        lng:{ required },
                        country:{ required },
                        state:{ required },
                        city:{ required },
                        street:{ required },
                        zip:{ required }
                    },
                    //profile_pic:{ required },
                    description:{ required },
                    //special_instructions:{ required },
                    //cancellation_policy:{ required },
                    status:{ required }
                }
            }
        },
        /*created() {
            this.getBusinessDetail()
        },*/
        directives: {mask},
        methods:{
            updateCountryCode(data){
                if (data.dialCode) {
                    this.business_.dialCode = data.dialCode
                }
            },
            toggleShow() {
                this.show = !this.show
            },
            cropSuccess(imgUrl, field){
                console.log('-------- crop success --------'+field);
                this.imgDataUrl = imgUrl
                this.business_.profile_pic = imgUrl
            },
            cropUploadSuccess(jsonData, field){
                console.log('-------- upload success --------');
                console.log(jsonData);
                console.log('field: ' + field);
            },
            cropUploadFail(status, field){
                console.log('-------- upload fail --------');
                console.log(status);
                console.log('field: ' + field);
            },
            getBusinessDetail(){
                let thiss = this
                axios.get('business_profile/'+this.$storage.getStorageSync('business').active_business.id).then(function (response) {
                    if (response.data.status == 'success') {
                        //console.log(response.data.data)
                        let res = response.data.data
                        thiss.business_.title = res.business_info.title
                        thiss.business_.dialCode = parseInt(res.business_info.phone.slice(0, -10))
                        thiss.business_.phone = res.business_info.phone ? res.business_info.phone.slice(-10) : res.business_info.phone
                        thiss.business_.address.complete_address = res.business_info.address
                        thiss.business_.address.city = res.business_info.city
                        thiss.business_.address.state = res.business_info.state
                        thiss.business_.address.street = res.business_info.street
                        thiss.business_.address.neighborhood = res.business_info.neighborhood
                        thiss.business_.address.zip = res.business_info.zip
                        thiss.business_.address.country = res.business_info.country
                        thiss.business_.apt_suite = res.business_info.apt_suite
                        thiss.business_.address.lat = parseFloat(res.business_info.lat)
                        thiss.business_.address.lng = parseFloat(res.business_info.lng)
                        thiss.business_.description = res.business_info.description
                        thiss.business_.special_instructions = res.business_info.special_instructions
                        thiss.business_.cancellation_policy = res.business_info.cancellation_policy
                        thiss.business_.status = res.business_info.status
                        thiss.business_.timeZone = res.business_info.timeZone
                        thiss.timeZones = res.time_zones
                        if (res.business_info.profile_pic != '' && res.business_info.profile_pic != null && res.business_info.profile_pic != undefined && response.data.data.profile_pic != 'undefined') {
                            thiss.imgDataUrl = response.data.data.profile_pic
                        }else{
                            thiss.imgDataUrl = 'https://s3.us-east-2.amazonaws.com/images.ondaq.com/business_logo/default.jpg'
                        }

                        let facility = res.business_info.facilities;
                        if (facility !== null && facility !== undefined && facility !== '') {
                            let aaa =facility.split(",");
                            //console.log(aaa)
                            for (var index = 0; index < aaa.length; index++) {
                                let b= aaa[index].split(": ");
                                if(b[0] == "Free Parking"){
                                thiss.business_.facilities.free_parking = b[1];
                                }
                                if(b[0] == "Free Wifi"){
                                thiss.business_.facilities.wifi = b[1];
                                }
                                if(b[0] == "Reception"){
                                thiss.business_.facilities.reception = b[1];
                                }
                                if(b[0] == "Kids Friendly"){
                                thiss.business_.facilities.kids_friendly = b[1];
                                }
                                if(b[0] == "Languages"){
                                thiss.business_.facilities.language = b[1].split("_");
                                }
                                if(b[0] == "Payment methods"){
                                thiss.business_.facilities.Payment_method = b[1].split("_");
                                }
                            }
                        }


                        //thiss.business_.profile_pic = res.business_info.profile_pic

                        // thiss.business_.profile_pic = res.business_info.profile_pic
                        // if (thiss.business_.profile_pic != '' && thiss.business_.profile_pic != null && thiss.business_.profile_pic != undefined) {
                        //     thiss.imgDataUrl = thiss.business_.profile_pic
                        // }else{
                        //     thiss.imgDataUrl = 'https://s3.us-east-2.amazonaws.com/images.ondaq.com/business_logo/default.jpg'
                        // }

                        thiss.drawMap()
                    }else{
                        thiss.business_.dialCode = 1
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message)
                    }
                    thiss.business_.dialCode = 1
                });
            },
            updateProfile(){
                this.v$.business_.$touch()
                if (!this.v$.business_.$error){
                    console.log(this.business_)

                    let thiss = this
                    axios.post('update_business_profile',this.business_).then(function (response) {
                        if (response.data.status == 'success') {
                            console.log(response.data)
                            thiss.toast.success(response.data.message)
                        }else if (response.data.status == 'login') {
                            thiss.$storage.removeStorageSync("user")
                            thiss.$storage.removeStorageSync("token")
                            thiss.$storage.removeStorageSync("business")
                            thiss.$router.push({ name: 'Home'})
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status == 422) {
                                this.toast.error(error.response.data.message);
                            }else if (error.response.status == 401) {
                                this.toast.error(error.response.data.message,{position: "top-right"});
                            }
                        }
                    });
                }
            },
            getAddressArray(place){
                var comp_address = {complete_address:'',lat:'',lng:'',street:'',city:'',state:'',country:'',zip:'',neighborhood:''}
                comp_address.complete_address = place.formatted_address
                comp_address.lat = place.geometry.location.lat()
                comp_address.lng = place.geometry.location.lng()
                for (var i = 0; i < place.address_components.length; i++) {
                    var addressType = place.address_components[i].types[0];
                    if (addressType == "street_number") {
                        comp_address.street += place.address_components[i]['short_name']
                    }else if (addressType == "route") {
                        comp_address.street += ' '+place.address_components[i]['long_name']+' '
                    }else if (addressType == "locality") {
                        comp_address.city = place.address_components[i]['long_name']
                    }else if (addressType == "administrative_area_level_1") {
                        comp_address.state = place.address_components[i]['short_name']
                    }else if (addressType == "country") {
                        comp_address.country = place.address_components[i]['long_name']
                    }else if (addressType == "postal_code") {
                        comp_address.zip = place.address_components[i]['short_name']
                    }else if(addressType == "neighborhood"){
                        comp_address.neighborhood = place.address_components[i]['short_name']
                    }
                }
                this.business_.address = comp_address
                console.log(this.business_.address)
            },
            drawMap(){
                const myLatLng = { lat: parseFloat(this.business_.address.lat), lng: parseFloat(this.business_.address.lng) };
                const map = new window.google.maps.Map(this.$refs["map"], {
                    center: myLatLng,
                    zoom: 16,
                    mapTypeControl: false,
                    //fullscreenControl: false,
                    streetViewControl: false,
                    mapTypeId: 'roadmap'
                });
                new window.google.maps.Marker({
                    position: myLatLng,
                    map,
                    title: "Hello World!",
                });

                // Configer Get Data From Autocomplete
                let thiss = this
                const autocomplete = new window.google.maps.places.Autocomplete(this.$refs["origin"],{componentRestrictions:{country:['US','CA','PK']}});
                var markers = [];

                autocomplete.addListener('place_changed', function () {
                    const place = autocomplete.getPlace();

                    // Get Complete Address Array
                    thiss.getAddressArray(place)

                    // Clear all Old markers
                    markers.forEach(function (marker) {
                        marker.setMap(null);
                    });
                    markers = [];

                    // Draw New Marker on Map
                    var icon = {
                        url: place.icon,
                        size: new window.google.maps.Size(71, 71),
                        origin: new window.google.maps.Point(0, 0),
                        anchor: new window.google.maps.Point(17, 34),
                        scaledSize: new window.google.maps.Size(25, 25)
                    };
                    var newMarker = new window.google.maps.Marker({
                        map: map,
                        icon: icon,
                        draggable:true,
                        title: place.name,
                        position: place.geometry.location
                    });
                    markers.push(newMarker);

                    // Set Map Bounds in Center
                    var bounds = new window.google.maps.LatLngBounds();
                    if (place.geometry.viewport) {
                        bounds.union(place.geometry.viewport);
                    } else {
                        bounds.extend(place.geometry.location);
                    }
                    map.fitBounds(bounds);

                    // Update position after drag the marker
                    var geocoder = new window.google.maps.Geocoder();
                    window.google.maps.event.addListener(newMarker,'dragend', function(){
                        geocoder.geocode({'latLng': newMarker.getPosition()}, function(results, status) {
                            if (status == window.google.maps.GeocoderStatus.OK) {
                                if (results[0]) {
                                    thiss.getAddressArray(results[0])
                                }
                            }
                        })
                    });
                })
                map.addListener('bounds_changed', function () {
                    autocomplete.setBounds(map.getBounds());
                });
            },
            generateAIDescription(){
                if (this.AIDescription != '') {
                    $("#AIDescriptionModal").modal('show')
                }else{
                    let thiss = this
                    axios.post('generate_AI_description').then(function (response) {
                        if (response.data.status == 'success') {
                            $("#AIDescriptionModal").modal('show')
                            thiss.AIDescription = response.data.data
                            console.log(response.data)
                        }
                    });
                }
            },
            updateDescription(desc){
                this.business_.description = desc
                $("#AIDescriptionModal").modal('hide')
            }
        },
        mounted() {
            // Display Map
            this.getBusinessDetail()

            // const map = new window.google.maps.Map(this.$refs["map"], {
            //     center: { lat: this.business_.address.lat, lng: this.business_.address.lng },
            //     zoom: 8,
            //     mapTypeControl: false,
            //     //fullscreenControl: false,
            //     streetViewControl: false,
            //     mapTypeId: 'roadmap'
            // });

            // // Configer Get Data From Autocomplete
            // let thiss = this
            // const autocomplete = new window.google.maps.places.Autocomplete(this.$refs["origin"],{componentRestrictions:{country:['US','CA']}});
            // var markers = [];
            // autocomplete.addListener('place_changed', function () {
            //     const place = autocomplete.getPlace();

            //     // Get Complete Address Array
            //     thiss.getAddressArray(place)

            //     // Clear all Old markers
            //     markers.forEach(function (marker) {
            //         marker.setMap(null);
            //     });
            //     markers = [];

            //     // Draw New Marker on Map
            //     var icon = {
            //         url: place.icon,
            //         size: new window.google.maps.Size(71, 71),
            //         origin: new window.google.maps.Point(0, 0),
            //         anchor: new window.google.maps.Point(17, 34),
            //         scaledSize: new window.google.maps.Size(25, 25)
            //     };
            //     var newMarker = new window.google.maps.Marker({
            //         map: map,
            //         icon: icon,
            //         draggable:true,
            //         title: place.name,
            //         position: place.geometry.location
            //     });
            //     markers.push(newMarker);

            //     // Set Map Bounds in Center
            //     var bounds = new window.google.maps.LatLngBounds();
            //     if (place.geometry.viewport) {
            //         bounds.union(place.geometry.viewport);
            //     } else {
            //         bounds.extend(place.geometry.location);
            //     }
            //     map.fitBounds(bounds);

            //     // Update position after drag the marker
            //     var geocoder = new window.google.maps.Geocoder();
            //     window.google.maps.event.addListener(newMarker,'dragend', function(){
            //         geocoder.geocode({'latLng': newMarker.getPosition()}, function(results, status) {
            //             if (status == window.google.maps.GeocoderStatus.OK) {
            //                 if (results[0]) {
            //                     thiss.getAddressArray(results[0])
            //                 }
            //             }
            //         })
            //     });
            // })
            // map.addListener('bounds_changed', function () {
            //     autocomplete.setBounds(map.getBounds());
            // });
        },
    }
</script>
<style scoped>
.email-deatils .form-group label{
    font-weight: 500;
}
.ai-description-link{
    float: right;
    padding-bottom: 10px;
    color: #6e317a;
}
</style>
<style type="text/css">
    .banner-img{
        position: relative;
        text-align: center;
        margin: 0 auto;
        max-height: 230px;
        max-width: 230px;
    }
    .banner-img img{
        border: 1px solid #6e317a;
        border-radius: 8px;
        /* margin-top: 5px;
        border : 1px solid #6e317a*/
    }
    .camera_icon {
        position: absolute;
        font-size: 20px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        width: 35px;
        height: 33px;
        border: 1px solid #6e317a;
        color: #6e317a
    }
    .vue-image-crop-upload .vicp-wrap .vicp-step1 .vicp-drop-area .vicp-hint, .vue-image-crop-upload .vicp-wrap .vicp-operate a, .vue-image-crop-upload .vicp-wrap .vicp-step2 .vicp-crop .vicp-crop-right .vicp-preview .vicp-preview-item span{
        color: #333333
    }
    .containerw input:checked~.checkmark {
    background-color: #6e317a;
    border: 2px solid #6e317a;
    }
</style>
